<template>
    <div class="sellCoin-wrap">
        <van-nav-bar
            left-arrow
            title="banner详情"
            :border="false"
            @click-left="onClickLeft"
        />
        <div class="sellCoin-main-wrap">
            <van-image class="avatar" :src="themeImgs" />
        </div> 
    </div>
</template>

<script>
import { useRoute } from 'vue-router'

export default {
    setup () {
        // 点击导航栏左侧返回键
        const $route = useRoute()

        const themeImgs = $route.query.pic
        const onClickLeft = () => {
            history.back()
        }
        return {
            themeImgs,
            onClickLeft
        }
    }
}
</script>

<style lang="less" scoped>
.sellCoin-wrap {
    .sellCoin-main-wrap {
        min-height: calc(100vh - 46px);
        padding: 24px 16px 16px;
        background: var(--mainBgColor);
    }
}
</style>
